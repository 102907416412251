import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertCtrl: AlertController
  ) { }

  showErrorAlert(mes: any) {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        header: 'Error',
        message: mes,
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }


  showSuccessAlert(mes: any) {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        header: 'Success',
        message: mes,
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }
}
